import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class TransactionService {

  private url = 'http://crypto-api.ssouza.com.br/transactions';

  constructor(private httpClient: HttpClient) { 

  }

  getTransactions() {
    return this.httpClient.get(this.url)
  }

  deleteTransaction(uuid:string) {
    return this.httpClient.delete(this.url + "/" + uuid);
  }

  saveTransaction(transaction: object) {
    return this.httpClient.post(this.url, transaction);
  }

}
