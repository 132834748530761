import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AssetService {

  private url = 'http://crypto-api.ssouza.com.br/assets';

  constructor(private httpClient: HttpClient) { 

  }

  getAssets() {
    return this.httpClient.get(this.url)
  }

}
