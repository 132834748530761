import { Component, OnInit } from '@angular/core';
import { TransactionService } from './services/transaction.service';
import { AssetService } from './services/asset.service';
import { BalanceService } from './services/balance.service';

import { Inject } from '@angular/core';
import { DOCUMENT, formatNumber } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  transactions: any;
  balance: any;
  balanceBackup: any;
  assets: any;
  date: any;
  transaction: any;
  document: any;
  obfuscated: boolean = false;
  isNewTransaction: boolean = true;

  constructor(private toastr: ToastrService,
    private transactionService: TransactionService,
    private assetService: AssetService,
    private balanceService: BalanceService,
    @Inject(DOCUMENT) document: Document) {
    this.document = document;
  }

  ngOnInit() {
    this.loadBalance();
    this.resetTransactionForm();
  }
  
  calculateUnitPrice() {
    var unitPrice = 0.0;
    if (this.transaction.quantity && this.transaction.total) {
      if (parseFloat(this.transaction.quantity) > 0.0 && parseFloat(this.transaction.total) > 0.0) {
        unitPrice = this.transaction.total / this.transaction.quantity;
      }
    }
    this.transaction.unitPrice = formatNumber(unitPrice, 'en-US', '1.8-8');
  }

  loadBalance() {
    this.balance = [];
    this.balanceService.getBalance().subscribe(response => {
      this.balance = response;
    });
    this.obfuscated = false;
    this.date = new Date();
  }

  loadAssets() {
    this.assets = [];
    this.assetService.getAssets().subscribe(response => {
      this.assets = response;
    });
  }

  loadTransactions() {
    this.transactions = [];
    this.transactionService.getTransactions().subscribe(response => {
      this.transactions = response;
    });
    this.obfuscated = false;
  }

  deleteTransaction(uuid: string) {
    if (confirm("Confirmar a exclusão da transação?")) {
      this.transactionService.deleteTransaction(uuid).subscribe(response => {
        this.loadTransactions();
        this.toastr.success('Transação removida com sucesso!', 'Sucesso');
      });
    }
  }

  resetTransactionForm() {
    this.transaction = { asset: {} };
    this.isNewTransaction = true;
  }

  newTransaction() {
    this.resetTransactionForm();
    this.loadAssets();
  }

  editTransaction(transaction: object) {
    this.transaction = Object.assign({}, transaction);
    this.isNewTransaction = false;
    this.loadAssets();
  }

  private isInvalid(value: any) {
    return (typeof value === 'undefined' || value === null);
  }
  private validateForm() {
    if (this.isInvalid(this.transaction.asset.uuid) || this.isInvalid(this.transaction.type)
      || this.isInvalid(this.transaction.date) || this.isInvalid(this.transaction.total) || this.isInvalid(this.transaction.quantity)) {
      this.toastr.error('Campos obrigatórios requeridos!', 'Validação');
      return false;
    }
    return true;
  }

  saveTransaction() {
    if (this.validateForm()) {
      this.transactionService.saveTransaction(this.transaction).subscribe(response => {
        this.document.getElementById('btnCloseForm').click();
        this.loadTransactions();
        this.resetTransactionForm();
        this.toastr.success('Transação salva com sucesso!', 'Sucesso');
      }, (err) => {
        this.toastr.error(err.error.message, 'Validação');
      });
    }
  }

  obfuscate() {
    if (this.obfuscated) {
      var elements = this.document.getElementsByClassName('obfuscated')
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
      }
      var elements = this.document.getElementsByClassName('no-obfuscated')
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'table-cell';
      }
      this.obfuscated = false;

    } else {
      var elements = this.document.getElementsByClassName('no-obfuscated')
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'none';
      }
      var elements = this.document.getElementsByClassName('obfuscated')
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.display = 'table-cell';
      }
      this.obfuscated = true;
    }

  }
}