import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BalanceService {

  private url = 'http://crypto-api.ssouza.com.br/balance';

  constructor(private httpClient: HttpClient) { 

  }

  getBalance() {
    return this.httpClient.get(this.url)
  }

}
