<div class="row">
  <div class="col-lg-12">
    <div class="ibox">
      <div class="ibox-content">
        <span class="text-muted small float-right"><i class="fa fa-eye" *ngIf="obfuscated === false" (click)="obfuscate()"></i><i class="fa fa-eye-slash" *ngIf="obfuscated === true" (click)="obfuscate()"></i> Balanço atualizado em: <i class="fa fa-clock-o"></i> {{ date | date:
          'HH:mm - dd/MM/yyyy' }}</span>
        <h2>Crypto Manager</h2>
        <button type="button" class="btn btn-primary float-right" (click)="newTransaction()" data-toggle="modal"
          data-target="#transactionForm">
          <i class="fa fa-exchange"></i> &nbsp;Compra e Venda
        </button>
        <p>Gerenciamento do meu portfólio de criptos</p>

        <div class="modal inmodal" id="transactionForm" tabindex="-1" role="dialog" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content animated bounceInRight">
              <div class="modal-header">
                <button type="button" id="btnCloseForm" class="close" data-dismiss="modal"><span
                    aria-hidden="true">&times;</span><span class="sr-only">Close</span></button>
                <h4 class="modal-title"><i class="fa fa-exchange"></i> &nbsp;Compra e Venda</h4>
              </div>
              <div class="modal-body">
                <div class="form-group">
                  <label>Moeda<small class="text-danger">*</small></label>
                  <select class="form-control" name="account" [(ngModel)]="transaction.asset.uuid" #ctrlName="ngModel" [disabled]="!isNewTransaction"
                    required>
                    <option *ngFor="let item of assets" [value]="item.uuid">{{item.name}}</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Tipo<small class="text-danger">*</small></label>
                  <select class="form-control" name="type" [(ngModel)]="transaction.type" #ctrlName="ngModel" required [disabled]="!isNewTransaction">
                    <option value="BUY">Compra</option>
                    <option value="SELL">Venda</option>
                  </select>
                </div>

                <div class="form-group">
                  <label>Data<small class="text-danger">*</small></label>
                  <input type="date" name="date" class="form-control" placeholder="00/00/0000"
                    [(ngModel)]="transaction.date" #ctrlName="ngModel" required>
                </div>

                <div class="form-group">
                  <label>Quantidade<small class="text-danger">*</small></label>
                  <input type="number" name="quantity" class="form-control" placeholder="0.00"
                    [(ngModel)]="transaction.quantity" #ctrlName="ngModel" required min="0"
                    (ngModelChange)="calculateUnitPrice()">
                </div>

                <div class="form-group">
                  <label>Valor Total<small class="text-danger">*</small></label>
                  <input type="number" name="total" class="form-control" placeholder="0.00"
                    [(ngModel)]="transaction.total" #ctrlName="ngModel" required min="0"
                    (ngModelChange)="calculateUnitPrice()">
                </div>

                <div class="form-group">
                  <label>Valor Unitário</label>
                  <input type="text" name="unitPrice" class="form-control" placeholder="0.00000000"       
                    [disabled]="true"
                    [(ngModel)]="transaction.unitPrice" #ctrlName="ngModel" required>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
                <button type="button" class="btn btn-primary" (click)="saveTransaction()">Salvar</button>
              </div>
            </div>
          </div>
        </div>


        <!-- <div class="input-group">
                              <input type="text" placeholder="Search client " class="input form-control">
                              <span class="input-group-append">
                                      <button type="button" class="btn btn btn-primary"> <i class="fa fa-search"></i> Search</button>
                              </span>
                          </div> -->
        <div>
          <!-- <span class="float-right small text-muted">1406 Elements</span> -->
          <ul class="nav nav-tabs">
            <li><a class="nav-link active" data-toggle="tab" href="#tab-1" (click)="loadBalance()"><i
                  class="fa fa-dashboard"></i> Balanço</a>
            </li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-3" (click)="loadTransactions()"><i
                  class="fa fa-exchange"></i> Compra e Venda</a>
            </li>
            <li><a class="nav-link" data-toggle="tab" href="#tab-2" (click)="loadAssets()"><i class="fa fa-money"></i>
                Moedas</a>
            </li>
          </ul>
          <div class="tab-content">
            <div id="tab-1" class="tab-pane active">
              <div class="full-height-scroll">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th colspan="2">Moeda</th>
                        <th class="text-right">Quantidade</th>
                        <th class="text-right">Total em Compra</th>
                        <th class="text-right">Preço Médio em Compra/Atual</th>
                        <th class="text-right">Total em Venda</th>
                        <th class="text-right">Lucro/Prejuízo</th>
                      </tr>
                    </thead>
                    <tbody *ngIf="balance">
                      <tr *ngFor="let item of balance.positions">
                        <td class="asset-logo"><img alt="image" title="{{ item.asset.code }}"
                            src="{{ item.asset.url }}"> </td>
                        <td>{{ item.asset.name }}<br/><small class="text-muted" title="1ª compra em">{{ item.since | date: 'dd/MM/yyyy' }}</small></td>
                        <td class="text-right no-obfuscated">{{ item.quantity | number : '1.0-8' }} <small>{{ item.asset.code }}</small><br/><small class="text-muted">{{ item.allocationPercent | percent: '1.2-2' }}</small></td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right no-obfuscated text-navy">{{ item.totalPurchases | currency }}</td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right no-obfuscated">{{ item.averagePrice | currency: 'BRL': 'R$': '1.8-8' }}<br/><small class="text-muted">{{ item.currentPrice | currency: 'BRL': 'R$': '1.8-8' }}</small></td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right no-obfuscated text-danger">{{ item.totalSales | currency }}</td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right no-obfuscated">{{ item.profitLossValue | currency }}<br/>
                          <small class="{{item.profitLossPercentValue < 0.0 ? 'text-danger' : 'text-navy'}}"><i class="fa fa-caret-down" *ngIf="item.profitLossPercentValue < 0.0"></i><i class="fa fa-caret-up" *ngIf="item.profitLossPercentValue >= 0.0"></i> {{ (item.profitLossPercentValue < 0.0 ? item.profitLossPercentValue * -1.0 : item.profitLossPercentValue) | percent: '1.2-2' }}</small></td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                      </tr>
                    </tbody>
                    <tfoot *ngIf="balance && balance.positions">
                      <tr>
                        <td colspan="2" class="font-weight-bolder">Total</td>
                        <td class="text-right"></td>
                        <td class="text-right text-navy font-weight-bolder no-obfuscated">{{ balance.buyTotal | currency }}</td>
                        <td class="text-right font-weight-bolder obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right"></td>
                        <td class="text-right font-weight-bolder obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right text-danger font-weight-bolder no-obfuscated">{{ balance.sellTotal | currency }}</td>
                        <td class="text-right font-weight-bolder obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right font-weight-bolder no-obfuscated">
                          {{balance.profitLossTotal | currency }}<br/>
                          <span class="{{balance.profitLossPercentTotal < 0.0 ? 'text-danger' : 'text-navy'}}"><i class="fa fa-caret-down" *ngIf="balance.profitLossPercentTotal < 0.0"></i><i class="fa fa-caret-up" *ngIf="balance.profitLossPercentTotal >= 0.0"></i> {{ (balance.profitLossPercentTotal < 0.0 ? balance.profitLossPercentTotal * -1.0 : balance.profitLossPercentTotal) | percent: '1.2-2' }}</span></td>
                          <!-- <td class="text-right font-weight-bolder obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right font-weight-bolder no-obfuscated {{balance.profitLossPercentTotal < 0.0 ? 'text-danger' : 'text-navy'}}">
                          {{balance.profitLossPercentTotal | percent: '1.2-2' }}</td> -->
                        <!-- <td class="text-right"></td> -->
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>


            <div id="tab-2" class="tab-pane">
              <div class="full-height-scroll">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th colspan="2">Moeda</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of assets">
                        <td class="asset-logo"><img alt="image" src="{{ item.url }}"> </td>
                        <td>{{ item.name }} ({{ item.code }})</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <div id="tab-3" class="tab-pane">
              <div class="full-height-scroll">
                <div class="table-responsive">
                  <table class="table table-hover table-striped">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th colspan="2">Moeda</th>
                        <th class="text-right">Quantidade</th>
                        <th class="text-right" class="text-right">Valor Unit.</th>
                        <th class="text-right">Total</th>
                        <th class="text-right">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of transactions">
                        <td style="width: 150px;">{{ item.date | date: 'dd/MM/yyyy' }}</td>
                        <td class="asset-logo"><img alt="image" title="{{ item.asset.code }}"
                            src="{{ item.asset.url }}"></td>
                        <td>{{ item.asset.name }}</td>
                        <td class="text-right no-obfuscated">{{ item.quantity | number : '1.0-8' }}</td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right no-obfuscated">{{ item.unitPrice | currency: 'BRL': 'R$': '1.8-8' }}</td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right no-obfuscated {{item.type === 'SELL' ? 'text-danger' : 'text-navy'}}">{{ item.total |
                          currency }}</td>
                        <td class="text-right obfuscated" style="display: none;">* * * *</td>
                        <td class="text-right">
                          <div class="btn-group">
                            <button class="btn btn-xs btn-white" (click)="editTransaction(item)" data-toggle="modal"
                              data-target="#transactionForm" title="Editar"><i class="fa fa-pencil"></i></button>&nbsp;
                            <button class="btn-white btn btn-xs" (click)="deleteTransaction(item.uuid)" title="Excluir"><i class="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>